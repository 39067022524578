<template>
  <footer id="footer" :class="determineHeaderFooterClass">
    <div class="container">
      <nav class="row">
        <div class="col">
          <div class="logo">
            <svg-logo></svg-logo>
          </div>
          <app-list v-if="!!camp" :list="camp"></app-list>
          <app-list :list="search"></app-list>
          <!-- <app-list :list="donate"></app-list> -->
        </div>
        <div class="col">
          <app-list :list="programs"></app-list>
          <app-list :list="auxiliary"></app-list>
        </div>
        <div class="col">
          <app-list :list="calendar"></app-list>
          <app-list :list="stayInformed"></app-list>
          <app-list :list="directory"></app-list>
        </div>
        <div class="col">
          <app-list :list="help"></app-list>
          <app-list :list="contact"></app-list>
          <app-list :list="resources"></app-list>
          <app-list :list="store"></app-list>
          <app-list :list="donate"></app-list>
        </div>
        <div class="col"></div>
      </nav>
      <div class="mobile-logo" v-if="isMobile()">
        <svg-logo></svg-logo>
      </div>
    </div>
  </footer>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapState } from 'vuex'
import { breakpoints } from '../../mixins/breakpoints'
import { empty_guid, faq_link, send_the_word_link, planned_giving_link } from '@/json/data.json'
import List from './List.vue'
import Logo from '@/assets/svgs/logo.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  mixins: [breakpoints, translationMixin],
  data() {
    return {
      programKey: '3892de40-0b28-4cf7-86b8-e28a0596bd91',
      i18n: {
        footer: {
          tcHelp: 'Help',
          tcSiteHelp: 'Site Help',
          tcFAQs: `FAQ's`,
          tcContactUs: 'Contact Us',
          tcSearch: 'Search',
          tcSearchSite: 'Search Site',
          tcResources: 'Resources',
          tcStore: 'Store',
          tcAuxiliary: 'Auxiliary',
          tcCalendar: 'Calendar',
          tcCamp: 'Camp',
          tcStayInformed: 'Stay Informed',
          tcPrograms: 'Programs',
          tcDonate: 'Donate',
          tcDirectory: 'Directory',
        },
      },
    }
  },
  async created() {
    await this.translateMenu(this.prefCulture)
    this.i18n = await this.getComponentTranslations('footer')
  },
  methods: {
    ...mapActions({
      translateMenu: 'translation/fetchMenuTranslations',
    }),
    menuNodeToFooter(name, topItemMenuKey, childItemMenuKey) {
      const result = [
        { text: name, to: '#', key: empty_guid },
        ...this.applicationMenu
          .find((til) => til.mnu_key === topItemMenuKey)
          .ChildItemList.find((cil) => cil.mnu_key === childItemMenuKey)
          .MenuItemList.map((mil) => {
            return { text: this.getMenuItemText(mil.mnu_key, mil.name), to: mil.href, key: mil.mnu_key }
          }),
      ]
      return result
    },
    getMenuItemText(guid, defaultText) {
      const translatedText = this.menuTranslations[guid]
      return translatedText ? translatedText : defaultText
    },
  },
  watch: {
    async prefCulture(newValue, oldValue) {
      this.i18n = await this.getComponentTranslations('footer')
    },
  },
  computed: {
    ...mapState({
      menuTranslations: (state) => state.translation.menuTranslations,
    }),
    ...mapGetters({
      applicationMenu: 'menu/applicationMenu',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userLogin: 'user/userLogin'
    }),
    isUSA() {
      return !!this.userLogin && !!this.userLogin.campnumber && this.userLogin.campnumber[0] === 'U'
    },
    determineHeaderFooterClass() {
      return process.env.VUE_APP_API_ROUTE_ADDRESS.includes('tgiapi.gideons.org') ? 'prod-footer' : 'beta-footer'
    },
    help() {
      return [
        { text: this.i18n['footer'].tcHelp, to: '#' },
        { text: this.i18n['footer'].tcSiteHelp, to: '/resources/media-portal' },
        { text: this.i18n['footer'].tcFAQs, to: '', href: faq_link },
      ]
    },
    contact() {
      return [
        { text: this.i18n['footer'].tcContactUs, to: '#' },
        { text: this.i18n['footer'].tcContactUs, to: '/help/contact-us' },
      ]
    },
    donate() {
      return [
        { text: this.i18n['footer'].tcDonate, to: '#' },
        { text: this.i18n['footer'].tcMakeAGift, to: '/resources/donations' },
        { text: this.i18n['footer'].tcSendAGideonCard, to: '', href: send_the_word_link + this.userId },
        { text: this.i18n['footer'].tcEstateAndPlannedGiving, to: '', href: planned_giving_link }
      ]
    },
    search() {
      return [
        { text: this.i18n['footer'].tcSearch, to: '#' },
        { text: this.i18n['footer'].tcSearchSite, to: '/search/search-site' },
      ]
    },
    resources() {
      try {
        if (this.applicationMenu.length > 0) {
          let r1 = this.menuNodeToFooter(
            this.i18n['footer'].tcResources,
            'd5400d81-7b5b-4dba-a556-50dd8f6cf38c',
            '094fb80f-dc57-4668-a05d-cfe6bc6f8dc5'
          )
          let r2 = this.menuNodeToFooter(
            'r2',
            '9e20026b-9a45-46cd-bc64-df7bd1e95d91',
            '8f20026b-9a45-46cd-bc64-df7bd1e95d7a'
          ).find((mnf) => mnf.key === 'ac858067-a8a8-46e9-84dd-0fe47aedae79')
          return [...r1, r2]
        } else {
          throw ''
        }
      } catch {
        return []
      }
    },
    store() {
      try {
        if (this.applicationMenu.length > 0) {
          return this.isUSA ? this.menuNodeToFooter(
            this.i18n['footer'].tcStore,
            'd5400d81-7b5b-4dba-a556-50dd8f6cf38c',
            '44844544-24a2-4c77-a41e-a2ed7eb34a9a'
          ) : null
        } else {
          throw ''
        }
      } catch {
        return []
      }
    },
    auxiliary() {
      try {
        if (this.applicationMenu.length > 0) {
          return this.menuNodeToFooter(
            this.i18n['footer'].tcAuxiliary,
            '9c75165a-d17f-468c-bf4a-d9ff254f5a33',
            '1249c129-3014-4e1a-b41c-e5ed312f7d4d'
          )
        } else {
          throw ''
        }
      } catch {
        return []
      }
    },
    calendar() {
      try {
        if (this.applicationMenu.length > 0) {
          return this.menuNodeToFooter(
            this.i18n['footer'].tcCalendar,
            '3ea13d43-5b59-4d21-81eb-44f0abbb1261',
            '7a3459fa-42bc-46c0-8b3f-de0cde29d95a'
          )
        } else {
          throw ''
        }
      } catch {
        return []
      }
    },
    camp() {
      try {
        if (this.applicationMenu.length > 0) {
          return this.menuNodeToFooter(
            this.i18n['footer'].tcCamp,
            'c8b1eb4c-691e-4443-8d0b-7b9bf50672f0',
            '80a91768-1575-4f6a-abbd-aa83a6f4ad01'
          )
        } else {
          throw ''
        }
      } catch {
        return []
      }
    },
    stayInformed() {
      try {
        if (this.applicationMenu.length > 0) {
          return this.menuNodeToFooter(
            this.i18n['footer'].tcStayInformed,
            '4e24dda5-3d11-4a96-9924-9f7546572c2c',
            'dbc46846-0a1c-4019-ad6c-e03f640f6ee7'
          )
        } else {
          throw ''
        }
      } catch {
        return []
      }
    },
    directory() {
      try {
        if (this.applicationMenu.length > 0) {
          return this.menuNodeToFooter(
            this.i18n['footer'].tcDirectory,
            '4e24dda5-3d11-4a96-9924-9f7546572c2c',
            '4205e63c-0e03-4177-9b23-b699c969eb52'
          )
        } else {
          throw ''
        }
      } catch {
        return []
      }
    },
    faithfund() {
      try {
        if (this.applicationMenu.length > 0) {
          return this.menuNodeToFooter('ff', this.programKey, '5551c3d3-e465-4d41-b138-7ac679e02b15')
        } else {
          throw ''
        }
      } catch {
        return []
      }
    },
    programs() {
      try {
        if (this.applicationMenu.length > 0) {
          let progs = this.applicationMenu
            .find((til) => til.mnu_key === this.programKey)
            .ChildItemList.map((cil) => cil.MenuItemList.find((mil, idx) => idx === 0))
          return [
            { text: this.i18n['footer'].tcPrograms, to: '#' },
            ...progs.map((pgs) => {
              return {
                text: this.getMenuItemText(pgs.mnu_key, pgs.name),
                to: pgs.href,
              }
            }),
          ]
        } else {
          throw ''
        }
      } catch {
        return []
      }
    },
  },
  components: {
    appList: List,
    svgLogo: Logo,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.prod-footer {
  background-color: #003946;
}

.beta-footer {
  background-color: #460000;
}

#footer {
  .container {
    padding-top: 48px;
    padding-bottom: 26px;
  }

  .logo {
    margin: 0 20px 20px 0;

    @include breakpoint(sm) {
      display: none;
    }
  }

  .mobile-logo {
    display: flex;
    justify-content: center;
  }

  .row {
    display: flex;
    flex-wrap: nowrap;

    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }

  .col {
    @include breakpoint(sm) {
      max-width: none !important;
      width: 50% !important;
      flex: 0 0 50% !important;
      padding: 0 2rem !important;
    }

    &:nth-of-type(1) {
      flex: 1 1 auto;
    }

    &:nth-of-type(2) {
      flex: 0 0 auto;
      max-width: 282px;
      padding-left: 0;
    }

    &:nth-of-type(3) {
      flex: 0 0 auto;
      max-width: 284px;
      padding-left: 0;
    }

    &:nth-of-type(4) {
      flex: 0 0 auto;
      max-width: 190px;
      padding-left: 0;
    }
  }
}
</style>
